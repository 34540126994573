import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				 AutoCare Pro
			</title>
			<meta name={"description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:title"} content={" AutoCare Pro"} />
			<meta property={"og:description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
		</Helmet>
		<Components.Marque>
			<Override slot="text" />
			<Override slot="text1" />
		</Components.Marque>
		<Components.Header>
			<Override slot="link3">
				AutoCare Pro
			</Override>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/xxl.jpg?v=2024-09-20T08:16:48.576Z) center/cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--light">
				НАШІ ПОСЛУГИ
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--base"
				color="rgba(255, 255, 255, 0.58)"
				width="700px"
				align-self="center"
				md-align-self="auto"
				max-width="100%"
			>
				AutoCare Pro пропонує повний спектр послуг для вашого автомобіля, від стандартного технічного обслуговування до складних ремонтних робіт. Ми працюємо з найкращими матеріалами і обладнанням, щоб забезпечити надійність і тривалу роботу вашого авто.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Технічне обслуговування
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Регулярне технічне обслуговування, включаючи заміну масла, перевірку гальм, балансування коліс та заміну фільтрів, щоб ваше авто завжди було в ідеальному стані.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Ремонт двигуна
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Професійний ремонт двигунів будь-якої складності. Від заміни деталей до повної реконструкції — довірте нам догляд за вашим автомобілем.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Діагностика та ремонт електроніки
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Ми використовуємо сучасне діагностичне обладнання для виявлення будь-яких несправностей електроніки, забезпечуючи швидке та надійне усунення проблем.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Ремонт трансмісії
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Послуги з діагностики та ремонту трансмісій, включаючи заміну рідини та повне відновлення. Гарантуємо якісне обслуговування для плавної роботи вашого авто.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Послуги з шиномонтажу
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Професійні послуги з заміни та балансування шин. Гарантуємо безпечну та комфортну поїздку за будь-яких умов.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								В AutoCare Pro ми надаємо весь спектр послуг для забезпечення найвищої якості обслуговування вашого автомобіля. Незалежно від проблеми, ми тут, щоб забезпечити найкращі результати для вашого авто.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--light" sm-margin="0px 0px 50px 0px">
				У цьому розділі ви знайдете відповіді на найбільш поширені запитання щодо наших послуг. Ми прагнемо забезпечити вас усією необхідною інформацією для комфортного обслуговування у AutoCare Pro.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Що входить до стандартного технічного обслуговування?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						До стандартного обслуговування входять заміна масла, перевірка гальм, балансування коліс, заміна фільтрів і перевірка основних систем авто.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можу я вибрати механіка для свого автомобіля?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ви можете попросити конкретного механіка під час запису на прийом, якщо він доступний.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Які послуги включає ремонт трансмісії?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						До наших послуг входять діагностика, заміна рідини для трансмісії та повний ремонт або відновлення трансмісійних систем.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи пропонуєте ви комплексні пакети обслуговування?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми пропонуємо різноманітні пакети обслуговування, які можуть включати регулярні перевірки та знижки на ремонтні послуги.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можна запланувати обслуговування онлайн?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ви можете записатися на будь-яке обслуговування за допомогою нашого онлайн-сервісу бронювання або зателефонувавши нам безпосередньо.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="box1" />
			<Override slot="text3" />
			<Override slot="link3" />
			<Override slot="linkBox" />
			<Override slot="box3" />
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="SectionContent" />
			<Override slot="text" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="link4" />
		</Components.Footer>
		<Components.Policy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});